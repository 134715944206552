export const indoortree = [
    { 
        id: 'product1',
        url: 'https://www.growjoy.com/store/pc/catalog/spathiphyllum_plants_peace_lily_865_detail.jpg', 
        detailUrl: 'https://www.growjoy.com/store/pc/catalog/spathiphyllum_plants_peace_lily_865_detail.jpg',
        title: "Peace Lily", 
        price: {
            mrp: 200,
            cost: 99,
            discount: '47%',
        }
    },
    { 
        id: 'product2',
        url: 'https://tse1.mm.bing.net/th?id=OIP.0ij2Td3a1mZc7WoAI8WzwAHaHa&pid=Api&P=0', 
        detailUrl: 'https://tse1.mm.bing.net/th?id=OIP.0ij2Td3a1mZc7WoAI8WzwAHaHa&pid=Api&P=0',
        title:"Snake Mini",
        price: {
            mrp: 200,
            cost: 399,
            discount: '40%',
        }},
   { 
            id: 'product2',
            url: 'https://tse2.mm.bing.net/th?id=OIP.Pw931HoGtG0jEmOu9WTfZwHaFj&pid=Api&P=0', 
            detailUrl: 'https://tse2.mm.bing.net/th?id=OIP.Pw931HoGtG0jEmOu9WTfZwHaFj&pid=Api&P=0',
            title:"Broken Heart",
            price: {
                mrp: 200,
                cost: 399,
                discount: '40%',
            }},
            { 
                id: 'product2',
                url: 'https://perfectplantdeal.nl/wp-content/uploads/2020/10/Peperomia-Raindrop-1-kopie.jpg', 
                detailUrl: 'https://perfectplantdeal.nl/wp-content/uploads/2020/10/Peperomia-Raindrop-1-kopie.jpg',
                title:"Peperomia",
                price: {
                    mrp: 200,
                    cost: 399,
                    discount: '40%',
                }},
        
                { 
                    id: 'product2',
                    url: 'https://tse3.mm.bing.net/th?id=OIP.E7VCMbJT_yH5-0FEDiYLlgHaHa&pid=Api&P=0', 
                    detailUrl: 'https://tse3.mm.bing.net/th?id=OIP.E7VCMbJT_yH5-0FEDiYLlgHaHa&pid=Api&P=0',
                    title:"Monstera Deliciosa",
                    price: {
                        mrp: 200,
                        cost: 399,
                        discount: '40%',
                    }},
                    { 
                        id: 'product2',
                        url: 'https://kiwinurseries.com/wp-content/uploads/2020/04/money-plant-overview.jpg', 
                        detailUrl: 'https://kiwinurseries.com/wp-content/uploads/2020/04/money-plant-overview.jpg',
                        title:"Money Plant",
                        price: {
                            mrp: 200,
                            cost: 399,
                            discount: '40%',
                        }},
                        { 
                            id: 'product2',
                            url: 'https://tse3.mm.bing.net/th?id=OIP.I9CzmYZ_rQWWh4U6n6iSjAHaJ4&pid=Api&P=0', 
                            detailUrl: 'https://tse3.mm.bing.net/th?id=OIP.I9CzmYZ_rQWWh4U6n6iSjAHaJ4&pid=Api&P=0',
                            title:"String of Banana",
                            price: {
                                mrp: 200,
                                cost: 399,
                                discount: '40%',
                            }},
                            { 
                                id: 'product2',
                                url: 'https://tse3.mm.bing.net/th?id=OIP.t4m7P0paashtJwrDo20hbQHaHa&pid=Api&P=0', 
                                detailUrl: 'https://tse3.mm.bing.net/th?id=OIP.t4m7P0paashtJwrDo20hbQHaHa&pid=Api&P=0',
                                title:"Xanthosoma",
                                price: {
                                    mrp: 200,
                                    cost: 399,
                                    discount: '40%',
                                }},
                                { 
                                    id: 'product2',
                                    url: 'https://tse3.mm.bing.net/th?id=OIP.giB_Yfr3AeQRnw6H0706swHaF7&pid=Api&P=0', 
                                    detailUrl: 'https://tse3.mm.bing.net/th?id=OIP.giB_Yfr3AeQRnw6H0706swHaF7&pid=Api&P=0',
                                    title:"Many more",
                                    price: {
                                        mrp: 200,
                                        cost: 399,
                                        discount: '40%',
                                    }},
]