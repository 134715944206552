import React from 'react'
import Carousel from 'react-material-ui-carousel'
import "../homecomp/baneer.css";

const data = [
   
    "https://4.bp.blogspot.com/-TpQbGD2HlIc/UW5NTo5qW3I/AAAAAAAAb-c/iNX94B4-U8g/s1600/dream-home.jpg",
    "https://tse1.mm.bing.net/th?id=OIP.F5xGuB4dvKIYka-MsVW7TgHaE8&pid=Api&P=0",
    "https://tse3.mm.bing.net/th?id=OIP.7KptUj1rkW5czGaBreDQyQHaE8&pid=Api&P=0",
    "http://liveincontainer.com/wp-content/uploads/2015/03/luxury-container-home-designs.jpg",
    "https://tse2.mm.bing.net/th?id=OIP.lmTd0rX09QpHVfJt6ThPjAHaCI&pid=Api&P=0",
    "https://tse3.mm.bing.net/th?id=OIP.Xwncd6CKYyHT_sgBOAjo6AHaC9&pid=Api&P=0"
]

// console.log(data);

const Banner = () => {
    return (
        <>

            <Carousel
                className="carasousel"
                autoPlay={true}
                animation="slide"
                indicators={false}
                
                cycleNavigation={true}
                navButtonsProps={{
                    style: {
                        background: "#fff",
                        color: "#494949",
                        borderRadius: 0,
                        marginTop: -22,
                        height: "104px",
                    }
                }}>
                {
                    data.map((imag, i) => {
                        return (
                            <>
                                <img src={imag} alt="img" key={i} className="banner_img" />
                            </>
                        )
                    })
                }

            </Carousel>
        </>
    )
}

export default Banner;