export const off = [
    { 
        id: 'product42',
        url: 'https://iba.online/knowledge/site/assets/files/1295/grundriss-zellenbuero-800.1280x0.jpg', 
        detailUrl: 'https://iba.online/knowledge/site/assets/files/1295/grundriss-zellenbuero-800.1280x0.jpg',
        title: "Celluar", 
        
        description: 'We provide 30 type of house',
         
    },
    { 
        id: 'product43',
        url: 'https://iba.online/knowledge/site/assets/files/1297/grundriss_gruppenbuero_3-800.1280x0.jpg', 
        detailUrl: 'https://iba.online/knowledge/site/assets/files/1297/grundriss_gruppenbuero_3-800.1280x0.jpg',
        title: "Group",
        description: 'design and construction',
         
    },
    { 
        id: 'product44',
        url: 'https://iba.online/knowledge/site/assets/files/1298/grundriss-2-kombi-buero-800.1280x0.jpg',
        detailUrl: 'https://iba.online/knowledge/site/assets/files/1298/grundriss-2-kombi-buero-800.1280x0.jpg', 
        title:"Combi", 
        
        description: 'Best Place to work',
        
    },
    { 
        id: 'product45',
        url: 'https://iba.online/knowledge/site/assets/files/1299/grundriss-gossraumbuero-1-800.1280x0.jpg', 
        detailUrl: 'https://iba.online/knowledge/site/assets/files/1299/grundriss-gossraumbuero-1-800.1280x0.jpg',
        title: "Open", 
       
        description: 'Temple,Church,Mosque and Gurudwara',
        
    },
    { 
        id: 'product46',
        url: 'https://iba.online/knowledge/site/assets/files/1989/grundriss-open-space-2-800.1280x0.jpg',
        detailUrl: 'https://iba.online/knowledge/site/assets/files/1989/grundriss-open-space-2-800.1280x0.jpg', 
        title: "Modern",
      
        description: 'Every type of office we made',
         
    },
    
   

]