import React from 'react'
import "./newnavbar.css"
import { NavLink } from "react-router-dom";
function Newnavbar() {
  return (
    <div className="new_nav">
        <div className="nav_data">
            <div className="left_data">
           
            
            < NavLink to={`/products/House`} style={{ textDecoration: 'none' }} ><p>House</p></NavLink>
            < NavLink to={`/products/Indoortree`}  style={{ textDecoration: 'none' }}><p>Plant</p></NavLink>
            < NavLink to={`/products/Furniture`}  style={{ textDecoration: 'none' }}><p>Furniture</p></NavLink>
            < NavLink to={`/products/Design`}  style={{ textDecoration: 'none' }}><p>Design</p></NavLink>
            
            
            
            </div>
            <div className="right_data">
                
            </div>
        </div>
    </div>
  )
}

export default Newnavbar