import React from 'react'
import './furni.css'
import './house.css'
import { fur } from '../data/fur'
import ReactWhatsapp from 'react-whatsapp';
const Furnitures=()=> {
  const itemCard=fur.map((e)=>
    <div className="Card_items">
    <div className="Card">
    <div className="Card_img">
        <img src={e.url} alt="product" />
    </div>
    <p className="Card_name">{e.title}</p>
    <ReactWhatsapp number="+917709944260" message="`size: 
    { e.title}`" className="view_btn">Customize</ReactWhatsapp>
    </div>
  </div>
  )
  
   
  return (


      <div>
    <div className="furn">
    <div className="furniback">
     <div className='banner'>
     <p className='title'>Get your custom Furniture  your wish</p>
     </div>
     <div className='measure'>
    <p> Choose your material </p>
    <p> Choose your size </p>
    <p> Choose your design or we design </p>
    <ReactWhatsapp number="+917709944260" message="Furniture type :  
      Metarial :  
      Leather : 
      Size :" 
      className="cust_btn">Customize</ReactWhatsapp>
      </div>
     </div>
    </div>
  
    
    <div className='dispalytv'>
      {itemCard}
    </div>
    </div>
  )
}

export default Furnitures