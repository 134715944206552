export const fur = [
    { 
        id: 'product51',
        url: 'https://tse2.mm.bing.net/th?id=OIP.zq8SwxMLyGvDAHp4D7p4AQHaFb&pid=Api&P=0', 
        detailUrl: 'https://tse2.mm.bing.net/th?id=OIP.zq8SwxMLyGvDAHp4D7p4AQHaFb&pid=Api&P=0',
        title: "kid", 
        
        description: 'We provide 30 type of house',
         
    },
    { 
        id: 'product52',
        url: 'https://tse2.mm.bing.net/th?id=OIP.Pbx0Vkz9GUeAB3qx8AeocAHaGL&pid=Api&P=0', 
        detailUrl: 'https://tse2.mm.bing.net/th?id=OIP.Pbx0Vkz9GUeAB3qx8AeocAHaGL&pid=Api&P=0',
        title: "Sofa",
        description: 'design and construction',
         
    },
    
    { 
        id: 'product54',
        url: 'https://tse2.mm.bing.net/th?id=OIP.ihGA56WhgJIQQn34VCo03gHaFL&pid=Api&P=0',
        detailUrl: 'https://tse2.mm.bing.net/th?id=OIP.ihGA56WhgJIQQn34VCo03gHaFL&pid=Api&P=0', 
        title: "Living",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product55',
        url: 'https://tse3.mm.bing.net/th?id=OIP.FM-X9iqxPVwViSIzCd7axgHaFj&pid=Api&P=0',
        detailUrl: 'https://tse3.mm.bing.net/th?id=OIP.FM-X9iqxPVwViSIzCd7axgHaFj&pid=Api&P=0', 
        title: "Bedroom",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product56',
        url: 'https://tse1.mm.bing.net/th?id=OIP.NuwUGpKGPnRPHi_60SGk4gHaEr&pid=Api&P=0',
        detailUrl: 'https://tse1.mm.bing.net/th?id=OIP.NuwUGpKGPnRPHi_60SGk4gHaEr&pid=Api&P=0', 
        title: "Kitchen",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product57',
        url: 'https://tse2.mm.bing.net/th?id=OIP.NiPDHh3mk7zNiy_f34ETfgHaHa&pid=Api&P=0',
        detailUrl: 'https://tse2.mm.bing.net/th?id=OIP.NiPDHh3mk7zNiy_f34ETfgHaHa&pid=Api&P=0', 
        title: "Storage",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product58',
        url: 'https://tse4.mm.bing.net/th?id=OIP.yFtSni9k-T5H3HubBLOeAQHaFA&pid=Api&P=0',
        detailUrl: 'https://tse4.mm.bing.net/th?id=OIP.yFtSni9k-T5H3HubBLOeAQHaFA&pid=Api&P=0', 
        title: "Office",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product59',
        url: 'https://tse1.mm.bing.net/th?id=OIP.SzEUjYUn4lcjq_mT9be-1wHaE6&pid=Api&P=0',
        detailUrl: 'https://tse1.mm.bing.net/th?id=OIP.SzEUjYUn4lcjq_mT9be-1wHaE6&pid=Api&P=0', 
        title: "Lighting",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product60',
        url: 'https://tse3.mm.bing.net/th?id=OIP.w2GbvVZOcA0L3EZOIFb05gHaE7&pid=Api&P=0',
        detailUrl: 'https://tse3.mm.bing.net/th?id=OIP.w2GbvVZOcA0L3EZOIFb05gHaE7&pid=Api&P=0', 
        title: "Outdoor",
      
        description: 'Every type of office we made',
         
    },
    
   

]