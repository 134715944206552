import React from 'react'
import './tree.css'
import { NavLink } from "react-router-dom";

const Tree=()=> {
  return (
    <div className='plantcomp'>
        <div className='service'>
            <div className='leftservice'>
            <h3>Plant</h3>
            </div>
            <div className='rightservice'>
            < NavLink to={`/products/Indoortree`} >  <button className='treebtn'>View all</button></NavLink>
                
            </div>
            </div>
            <div className='imgs'>
              <div className="treelay">
                     <div className='treecomp'>
                      <img className='imgtree' src="https://5.imimg.com/data5/SELLER/Default/2021/6/VH/ZY/CO/12253376/indoor-plants-500x500.JPG" alt="alt" />
                      <p className="treename">Tree</p>
                      <div className='treecompdown'>
            < NavLink to={'/getproductsone/product101'}><button className='treebtn'>BUY</button></NavLink>
            </div>
            </div>
           
        
                     <div className='treecomp'>
                      <img className='imgtree' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKHTGJW85e2rMbdP2bunZzx7Wbo5-dHBfXjQ&usqp=CAU" alt="alt" />
                      <p className="products_explore">Tree</p>
                      <div className='treecompdown'>
            < NavLink to={'/getproductsone/product101'}><button className='treebtn'>BUY</button></NavLink>
            </div>
            </div>
           
        
                     <div className='treecomp'>
                      <img className='imgtree' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKMu1lGIThRsjm6slaxbdENEfi_E-CJIoIKg&usqp=CAU" alt="alt" />
                      <p className="products_explore">Tree</p>
                      <div className='treecompdown'>
            < NavLink to={'/getproductsone/product101'}><button className='treebtn'>BUY</button></NavLink>
            </div>
            </div>
          
     
                     <div className='treecomp'>
                      <img className='imgtree' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPoukCijEbYjnyM63hNW4CrGogaKbyl1u4DQ&usqp=CAU" alt="alt" />
                      <p className="products_explore">Tree</p>
                      <div className='treecompdown'>
            < NavLink to={'/getproductsone/product101'}><button className='treebtn'>BUY</button></NavLink>
            </div>
           
        </div>
        </div>
    </div>
    </div>
  )
}

export default Tree;