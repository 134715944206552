import React from 'react'
import {blue} from '../data/blue'
import './house.css';
import { NavLink } from "react-router-dom";
const Blueprint=()=> {
const itemCard=blue.map((e)=>
  <div className="Card_items">
  <div className="Card">
  <div className="Card_img">
      <img src={e.url} alt="product" />
  </div>
  <p className="Card_name">{e.title}</p>
  < NavLink to={'/getproductsone/product101'}><button className='treebtn'>BUY</button></NavLink>
  </div>
</div>
)


  return (
    <div className='dispalytv'>
      {itemCard}
    </div>
    
  )
  }

export default Blueprint