export const blue = [
    { 
        id: 'product51',
        url: 'https://www.wikihow.com/images/f/f7/Draw-Blueprints-for-a-House-Step-8.jpg', 
        detailUrl: 'https://www.wikihow.com/images/f/f7/Draw-Blueprints-for-a-House-Step-8.jpg',
        title: "2BHK", 
        
        description: 'We provide 30 type of house',
         
    },
    { 
        id: 'product52',
        url: 'http://cdn.shopify.com/s/files/1/2829/0660/products/Lochinvar-FIrst-Floor_M_800x.jpg?v=1534276759', 
        detailUrl: 'http://cdn.shopify.com/s/files/1/2829/0660/products/Lochinvar-FIrst-Floor_M_800x.jpg?v=1534276759',
        title: "Villa",
        description: 'design and construction',
         
    },
    
    { 
        id: 'product54',
        url: 'https://tse1.mm.bing.net/th?id=OIP.Ft7uAS4DYg8GisnffaS0LQHaLk&pid=Api&P=0',
        detailUrl: 'https://tse1.mm.bing.net/th?id=OIP.Ft7uAS4DYg8GisnffaS0LQHaLk&pid=Api&P=0', 
        title: "Mediterrance",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product55',
        url: 'https://tse1.mm.bing.net/th?id=OIP.my0WCIrNAxH68-IihBx7KwHaDY&pid=Api&P=0',
        detailUrl: 'https://tse1.mm.bing.net/th?id=OIP.my0WCIrNAxH68-IihBx7KwHaDY&pid=Api&P=0', 
        title: "Apartment",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product56',
        url: 'https://www.conceptdraw.com/solution-park/resource/images/solutions/cafe-and-restaurant-plans/Building-Cafe-Restaurant-Plans-Pizzeria-Plan.png',
        detailUrl: 'https://www.conceptdraw.com/solution-park/resource/images/solutions/cafe-and-restaurant-plans/Building-Cafe-Restaurant-Plans-Pizzeria-Plan.png', 
        title: "Cafe",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product57',
        url: 'https://i.pinimg.com/originals/4d/8b/1a/4d8b1a82510eab2aa5cc52945ac21360.png',
        detailUrl: 'https://i.pinimg.com/originals/4d/8b/1a/4d8b1a82510eab2aa5cc52945ac21360.png', 
        title: "School",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product58',
        url: 'https://i.pinimg.com/originals/43/61/f0/4361f007904ffece7355a02624ca86cb.jpg',
        detailUrl: 'https://i.pinimg.com/originals/43/61/f0/4361f007904ffece7355a02624ca86cb.jpg', 
        title: "Temple",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product59',
        url: 'https://tse2.mm.bing.net/th?id=OIP.q4NvPicQ6cl7Kraxw74vagHaEK&pid=Api&P=0',
        detailUrl: 'https://tse2.mm.bing.net/th?id=OIP.q4NvPicQ6cl7Kraxw74vagHaEK&pid=Api&P=0', 
        title: "Park",
      
        description: 'Every type of office we made',
         
    },
    
    
   

]