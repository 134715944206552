export const designh = [
    { 
        id: 'product6',
        url: 'https://houseandhome.com/wp-content/uploads/2020/04/BYB-Ep3029-Final-Afters2_HH_DEC20_25.jpg', 
        detailUrl: 'https://houseandhome.com/wp-content/uploads/2020/04/BYB-Ep3029-Final-Afters2_HH_DEC20_25.jpg',
        title: "Outdoor", 
        
        description: 'We provide 30 type of house',
         
    },

    { 
        id: 'product8',
        url: 'https://tse4.mm.bing.net/th?id=OIP.1BhhHSnFWX-XaerRMi5ArAHaFW&pid=Api&P=0', 
        detailUrl: 'https://tse4.mm.bing.net/th?id=OIP.1BhhHSnFWX-XaerRMi5ArAHaFW&pid=Api&P=0', 
        title:"Kitchen", 
        
        description: 'Best Place to work',
        
    },
    { 
        id: 'product9',
        url: 'https://tse2.mm.bing.net/th?id=OIP.yCR4jR7KhFD-LGT3YISj7wHaFj&pid=Api&P=0', 
        detailUrl: 'https://tse2.mm.bing.net/th?id=OIP.yCR4jR7KhFD-LGT3YISj7wHaFj&pid=Api&P=0',
        title: "Wall", 
       
        description: 'Temple,Church,Mosque and Gurudwara',
        
    },
    { 
        id: 'product10',
        url: 'https://tse4.mm.bing.net/th?id=OIP.VD6lCMjb0dgWxRh7PNCLtgHaGL&pid=Api&P=0',
        detailUrl: 'https://tse4.mm.bing.net/th?id=OIP.VD6lCMjb0dgWxRh7PNCLtgHaGL&pid=Api&P=0', 
        title: "Bathroom",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product11',
        url: 'https://tse3.mm.bing.net/th?id=OIP.S_xUpn0aM50BgS2BhsRnIAHaE7&pid=Api&P=0',
        detailUrl: 'https://tse3.mm.bing.net/th?id=OIP.S_xUpn0aM50BgS2BhsRnIAHaE7&pid=Api&P=0', 
        title: "Bedroom",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product12',
        url: 'https://tse3.mm.bing.net/th?id=OIP.DOb51Hz06N1fxw1FK0pjtAHaE8&pid=Api&P=0',
        detailUrl: 'https://tse3.mm.bing.net/th?id=OIP.DOb51Hz06N1fxw1FK0pjtAHaE8&pid=Api&P=0', 
        title: "Living",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product13',
        url: 'https://2.bp.blogspot.com/-tcUIFQ7X7No/W5DFrL2ZCjI/AAAAAAAALOA/gl0bVk5B3iEBdt09DdWWg1rj4HoJciAXgCLcBGAs/s1600/latest-fall-ceiling-designs-beautiful-latest-false-ceiling-designs-for-living-collection-and-design-bedroom-pictures-the-best-best-false-ceiling-designs-for-drawing-room.jpg',
        detailUrl: 'https://2.bp.blogspot.com/-tcUIFQ7X7No/W5DFrL2ZCjI/AAAAAAAALOA/gl0bVk5B3iEBdt09DdWWg1rj4HoJciAXgCLcBGAs/s1600/latest-fall-ceiling-designs-beautiful-latest-false-ceiling-designs-for-living-collection-and-design-bedroom-pictures-the-best-best-false-ceiling-designs-for-drawing-room.jpg', 
        title: "Ceiling",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product14',
        url: 'https://4.bp.blogspot.com/-fOLZqNG3_-E/V7rh4RGFD4I/AAAAAAAANz8/jasypPQdHscC6quYDD1Ect8_6yUQK347QCEw/s1600/3D-bathroom-tile-flooring-designs-.jpg',
        detailUrl: 'https://4.bp.blogspot.com/-fOLZqNG3_-E/V7rh4RGFD4I/AAAAAAAANz8/jasypPQdHscC6quYDD1Ect8_6yUQK347QCEw/s1600/3D-bathroom-tile-flooring-designs-.jpg', 
        title: "tiles",
      
        description: 'Every type of office we made',
         
    }
]