export const features = [
    { 
        id: 'product15',
        url: 'https://www.homestratosphere.com/wp-content/uploads/2018/02/mansion-contemporary-hs-870x579.jpg.webp', 
        detailUrl: 'https://www.homestratosphere.com/wp-content/uploads/2018/02/mansion-contemporary-hs-870x579.jpg.webp',
        title: "House", 
        
        description: 'We provide 30 type of house',
         
    },
    { 
        id: 'product16',
        url: 'https://tse2.mm.bing.net/th?id=OIP.sljaMO6IW-MkS_xqnKwa5QHaE8&pid=Api&P=0', 
        detailUrl: 'https://tse2.mm.bing.net/th?id=OIP.sljaMO6IW-MkS_xqnKwa5QHaE8&pid=Api&P=0',
        title: "Industry",
        description: 'design and construction',
         
    },
    { 
        id: 'product17',
        url: 'https://tse1.mm.bing.net/th?id=OIP.dlyXmGt8uHqFOqCEWowjEgHaF7&pid=Api&P=0&h=180', 
        detailUrl: 'https://tse1.mm.bing.net/th?id=OIP.dlyXmGt8uHqFOqCEWowjEgHaF7&pid=Api&P=0&h=180', 
        title:"Cafe", 
        
        description: 'Best Place to work',
        
    },
    { 
        id: 'product18',
        url: 'https://tse2.mm.bing.net/th?id=OIP.Ka2pA4seLm1f1Q87mbrrzgHaEc&pid=Api&P=0', 
        detailUrl: 'https://tse2.mm.bing.net/th?id=OIP.Ka2pA4seLm1f1Q87mbrrzgHaEc&pid=Api&P=0',
        title: "Godhome", 
       
        description: 'Temple,Church,Mosque and Gurudwara',
        
    },
    { 
        id: 'product19',
        url: 'https://tse3.mm.bing.net/th?id=OIP.R29lNc28LUNS0fz0Sb7PEQHaEh&pid=Api&P=0',
        detailUrl: 'https://tse3.mm.bing.net/th?id=OIP.R29lNc28LUNS0fz0Sb7PEQHaEh&pid=Api&P=0', 
        title: "Office",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product5',
        url: 'https://tse3.mm.bing.net/th?id=OIP.RZVei41-BhLiNfywm0cd3gHaDf&pid=Api&P=0',
        detailUrl: 'https://tse3.mm.bing.net/th?id=OIP.RZVei41-BhLiNfywm0cd3gHaDf&pid=Api&P=0', 
        title: "College",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product20',
        url: 'https://www.eyerevolution.co.uk/wp-content/uploads/2009/10/emirates-palace1.jpg',
        detailUrl: 'https://www.eyerevolution.co.uk/wp-content/uploads/2009/10/emirates-palace1.jpg', 
        title: "Hotel",
      
        description: 'Every type of office we made',
         
    }
]