export const indu = [
    { 
        id: 'product42',
        url: 'https://www.foodengineeringmag.com/ext/resources/Issues/2022/07-July/GettyImages-171319596.jpg?1656617102', 
        detailUrl: 'https://www.foodengineeringmag.com/ext/resources/Issues/2022/07-July/GettyImages-171319596.jpg?1656617102',
        title: "Food", 
        
        description: 'We provide 30 type of house',
         
    },
    { 
        id: 'product43',
        url: 'https://textilevaluechain.in/wp-content/uploads/2020/04/india-garmen-factory.jpg', 
        detailUrl: 'https://textilevaluechain.in/wp-content/uploads/2020/04/india-garmen-factory.jpg',
        title: "Garment",
        description: 'design and construction',
         
    },
    { 
        id: 'product44',
        url: 'https://i0.wp.com/www.maiervidorno.com/wp-content/uploads/2022/11/Linkedin-Events-2021-1920-%C3%97-1080-px-26.png?fit=1920%2C1080&ssl=1',
        detailUrl: 'https://i0.wp.com/www.maiervidorno.com/wp-content/uploads/2022/11/Linkedin-Events-2021-1920-%C3%97-1080-px-26.png?fit=1920%2C1080&ssl=1', 
        title:"Machinery", 
        
        description: 'Best Place to work',
        
    },
    { 
        id: 'product45',
        url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRx9833KEngGVfggK8kyU0Hlr7zWs_bN0XGXpo_k9UHzw&usqp=CAU&ec=48665698', 
        detailUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRx9833KEngGVfggK8kyU0Hlr7zWs_bN0XGXpo_k9UHzw&usqp=CAU&ec=48665698',
        title: "Chemical", 
       
        description: 'Temple,Church,Mosque and Gurudwara',
        
    },
    { 
        id: 'product46',
        url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtAPgQNVtkLpmFzrtPlCUGDEAGbYRvw07uZQkyAeKzZA&usqp=CAU&ec=48665698',
        detailUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtAPgQNVtkLpmFzrtPlCUGDEAGbYRvw07uZQkyAeKzZA&usqp=CAU&ec=48665698', 
        title: "Electronic",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product47',
        url: 'https://www.airex-industries.com/images/cck/airex_news_item_image/248/_thumb2/industriebois.webp',
        detailUrl: 'https://www.airex-industries.com/images/cck/airex_news_item_image/248/_thumb2/industriebois.webp', 
        title: "Wood",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product48',
        url: 'https://www.mcrsafety.com/~/media/mcrsafety/industry/hazards/primary-metals/steel-product-manufacturing/steel-product-main-page-images/steel-pipe.jpg?mw=400&hash=707C54DC18303C689D54529533997B8C',
        detailUrl: 'https://www.mcrsafety.com/~/media/mcrsafety/industry/hazards/primary-metals/steel-product-manufacturing/steel-product-main-page-images/steel-pipe.jpg?mw=400&hash=707C54DC18303C689D54529533997B8C', 
        title: "Metal",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product49',
        url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2CD-dpbvqp_5HEztvyuFD_5DOh2t8WB3pfYT9cBT6Tg&usqp=CAU&ec=48665698',
        detailUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2CD-dpbvqp_5HEztvyuFD_5DOh2t8WB3pfYT9cBT6Tg&usqp=CAU&ec=48665698', 
        title: "Paper",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product50',
        url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAwmcCKg-J8xjuVRiyzr0wguXmWyCcCV5iCxHA-AqaxA&usqp=CAU&ec=48665698',
        detailUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAwmcCKg-J8xjuVRiyzr0wguXmWyCcCV5iCxHA-AqaxA&usqp=CAU&ec=48665698', 
        title: "Sugar",
      
        description: 'Every type of office we made',
         
    }
   

]