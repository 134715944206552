
import React from 'react'
import Banner from './Banner'
import Slide from './Slide'
import Card from './Card'
import Design from './Design'
import Tree from "./Tree"
import Furniturede from './Furniturede'

const Maincomp=()=> {
  return (
    <div className='home_section'>
        <div className="banner_part">
            <Banner/>
        </div>
        
        <Card/>
        <Design/>
        <Tree/>
        <Furniturede/>
    </div>
  )
}

export default Maincomp

