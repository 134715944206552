import React from 'react'
import './tree.css'
import { NavLink } from "react-router-dom";
import ReactWhatsapp from 'react-whatsapp';

const Furniturede=()=> {
  return (
    <div className='plantcomp'>
        <div className='service'>
            <div className='leftservice'>
            <h3>Custom Furniture</h3>
            </div>
            <div className='rightservice'>
            < NavLink to={`/products/Furniture`} ><button className='treebtn'>View all</button></NavLink>
                
            </div>
            </div>
            <div className='imgs'>
              <div className="treelay">
                     <div className='treecomp'>
                      <img className='imgtree' src="https://sethrolland.com/wp-content/uploads/Wave-Desk-630x420-c-default.jpg" alt="alt" />
                      <p className="treename">Furniture</p>
                      <div className='treecompdown'>
                      <ReactWhatsapp number="+917709944260" message="Furniture type :  
      Metarial :  
      Leather : 
      Size :" 
      className="treebtnt">Customize</ReactWhatsapp>
            </div>
            </div>
           
        
                     <div className='treecomp'>
                      <img className='imgtree' src="https://fazzilet.com/wp-content/uploads/2022/08/il_794xN.3933940494_r4j4-600x450.webp" alt="alt" />
                      <p className="products_explore">Tree</p>
                      <div className='treecompdown'>
                      <ReactWhatsapp number="+917709944260" message="Furniture type :  
      Metarial:  
      Leather: 
      Size:" 
      className="treebtnt">Customize</ReactWhatsapp>
            </div>
            </div>
           
        
                     <div className='treecomp'>
                      <img className='imgtree' src="https://media.architecturaldigest.com/photos/5b6b45846f48c85479ae400c/16:9/w_2560%2Cc_limit/calivintage_campaignpillows.jpg" alt="alt" />
                      <p className="products_explore">Tree</p>
                      <div className='treecompdown'>
                      <ReactWhatsapp number="+917709944260" message="Furniture type :  
      Metarial:  
      Leather: 
      Size:" 
      className="treebtnt">Customize</ReactWhatsapp>
            </div>
            </div>
          
     
                     <div className='treecomp'>
                      <img className='imgtree' src="https://i.shgcdn.com/bc7bfd3e-7cd0-4f95-a9c7-67f052849eaf/-/format/auto/-/preview/3000x3000/-/quality/lighter/" alt="alt" />
                      <p className="products_explore">Tree</p>
                      <div className='treecompdown'>
                      <ReactWhatsapp number="+917709944260" message="Furniture type :  
      Metarial:  
      Leather: 
      Size:" 
      className="treebtnt">Customize</ReactWhatsapp>
            </div>
           
        </div>
        </div>
    </div>
    </div>
  )
}

export default Furniturede;