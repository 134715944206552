export const coll = [
    { 
        id: 'product65',
        url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfVbteNALj6vbX02lvT1r1zrytR9dnUtGzUe6Ija43Eg&usqp=CAU&ec=48665698', 
        detailUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfVbteNALj6vbX02lvT1r1zrytR9dnUtGzUe6Ija43Eg&usqp=CAU&ec=48665698',
        title: "School", 
        
        description: 'We provide 30 type of house',
         
    },
    { 
        id: 'product66',
        url: 'https://images.adsttc.com/media/images/5df0/c1e6/3312/fd16/7900/0637/large_jpg/%E5%9B%BE2_%E6%A0%A1%E5%9B%AD%E4%B8%BB%E5%85%A5%E5%8F%A3.jpg?1576059356', 
        detailUrl: 'https://images.adsttc.com/media/images/5df0/c1e6/3312/fd16/7900/0637/large_jpg/%E5%9B%BE2_%E6%A0%A1%E5%9B%AD%E4%B8%BB%E5%85%A5%E5%8F%A3.jpg?1576059356',
        title: "JR College",
        description: 'design and construction',
         
    },
    { 
        id: 'product67',
        url: 'https://mc.webpcache.epapr.in/mcms.php?size=medium&in=https://mcmscache.epapr.in/post_images/website_350/post_31583716/full.jpg',
        detailUrl: 'https://mc.webpcache.epapr.in/mcms.php?size=medium&in=https://mcmscache.epapr.in/post_images/website_350/post_31583716/full.jpg', 
        title:"University", 
        
        description: 'Best Place to work',
        
    },
    { 
        id: 'product68',
        url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEdg0XzymRn7ZC2odMv-zFYpw6bbD8MMCDrJMvcnEMfA&usqp=CAU&ec=48665698', 
        detailUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEdg0XzymRn7ZC2odMv-zFYpw6bbD8MMCDrJMvcnEMfA&usqp=CAU&ec=48665698',
        title: "Kindgarten", 
       
        description: 'Temple,Church,Mosque and Gurudwara',
        
    },
    
   

]